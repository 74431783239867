import React, { createContext } from 'react'

const defaultConfig = {
  api: '',
  environmentID: '',
  evalutationEvent: {},
  flags: {},
  traits: {},
}

/**
 * Context state management for Flagsmith, fetch once over api and use across clientside with React Context
 *
 * @param props.config Flagsmith config object
 */
export const FlagSmithStateContext = createContext(defaultConfig)

export const FlagSmithState = ({ config, currentUser, children }) => {
  return <FlagSmithStateContext.Provider value={config}>{children}</FlagSmithStateContext.Provider>
}

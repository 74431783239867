import React from 'react'

const Spinner = props => {
  return (
    <div className="spinner-background">
      <div className="spinner" />
    </div>
  )
}

export default Spinner

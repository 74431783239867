import { useState } from 'react'
import { Track } from 'services/analytics'
import { callApi } from 'services/config'
import { Row, Col } from 'components/grid'
import styles from './email_collect.scss'
import Icon from 'components/icon'
import { checkValidation } from 'styleguide/packages/util/src/validation'
import { Spinner } from 'styleguide/packages/components'
import { log } from 'services/util/log'

export const EmailCollect = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const submitForm = async e => {
    e.preventDefault()
    setError(null)
    setLoading(true)

    try {
      const validate = checkValidation(email, 'email')

      if (!validate) {
        await callApi('POST', '/newsletter', null, { email })
      } else {
        throw Error(validate)
      }

      Track.newsletterSignupSubmitted(email)
      setSuccess(true)
    } catch (e) {
      log('Failed to subscribe to newsletter', null, { error: e })
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form className="newsletter-form-v2 text-black" onSubmit={submitForm}>
      {success ? (
        <>
          <span className="subheader">
            <Icon name="checkmark" fontSize={24} className="pull-right" />
            Thank you for signing up!
          </span>
        </>
      ) : (
        <>
          <input
            id="newsletter-signup"
            type="email"
            placeholder="Email address"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
          />
          <button type="submit" className="overline" name="Submit" aria-label="Submit">
            {loading ? (
              <span className="spinner-wrapper">
                <Spinner />
              </span>
            ) : (
              <Icon name="arrow-right" />
            )}
          </button>
        </>
      )}

      {error && <span className="error">{error}</span>}
      <style jsx global>
        {styles}
      </style>
    </form>
  )
}

export const EmailCollectWrapper = props => {
  const { customClass = '' } = props

  return (
    <section className={`email-capture mb-3 ${customClass}`}>
      <Row align="center">
        <Col sm="6">
          <p className="body-text-lg text-center mbx-3">
            Want more exclusive content?
            <br /> Register for the AEDITION newsletter.
          </p>
        </Col>
        <Col sm="6">
          <EmailCollect />
        </Col>
      </Row>
      <style jsx global>
        {styles}
      </style>
    </section>
  )
}

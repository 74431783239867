import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ name, fontSize, className, style, ...rest }) => (
  <i
    {...rest}
    className={`fa fa-${name} ${className ? className : ''}`}
    style={{ ...style, fontSize: fontSize }}
  />
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}

Icon.defaultProps = {
  size: 'inherit',
}

export default Icon

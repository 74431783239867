import React, { useContext } from 'react'
import { FlagSmithStateContext } from 'services/flagsmith/state'
import { Track } from 'services/analytics'
import { EmailCollect } from 'components/email_collect'
import AeditLink from 'services/link'
import {
  LINKEDIN_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  PINTEREST_URL,
  YOUTUBE_URL,
  TIKTOK_URL,
} from 'services/constants'

const Footer = ({ className = '', ...rest }) => {
  const { flags } = useContext(FlagSmithStateContext)

  const onSocialClicked = social_network => {
    Track.socialClicked({ social_network })

    return () => {}
  }

  return (
    <footer className={`${className} py-10 px-6 bg-black text-white lg:px-12`} {...rest}>
      <div className="grid grid-cols-2 gap-x-4 gap-y-6 max-w-screen-xl mx-auto sm:grid-cols-4 lg:grid-cols-6">
        <div className="flex flex-col">
          <h2 className="t-body-sm font-maison-neue-demi tracking-[2px] leading-8 mb-4 text-xs font-medium uppercase">
            Discover
          </h2>
          <AeditLink className="t-body-sm mb-2" pathname="/aedition">
            The AEDITION
          </AeditLink>
          {flags.app_module.enabled && (
            <AeditLink className="t-body-sm mb-2" pathname="/aeditor-app">
              The AEDITOR
            </AeditLink>
          )}
          <AeditLink className="t-body-sm mb-2" pathname="/beauty-board">
            Advisory Boards
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/editorial-process">
            Editorial Process
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/editorial-team">
            Editorial Team
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/white-label-the-aeditor">
            Our Technology
          </AeditLink>
        </div>

        <div className="flex flex-col">
          <h2 className="t-body-sm font-maison-neue-demi tracking-[2px] leading-8 mb-4 text-xs font-medium uppercase">
            Company
          </h2>
          <AeditLink className="t-body-sm mb-2" pathname="/about">
            About Us
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/careers">
            Careers
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/contact-us">
            Contact Us
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/press">
            Press
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/privacy-policy">
            Privacy Policy
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/terms-and-conditions">
            Terms & Conditions
          </AeditLink>
        </div>

        <div className="flex flex-col">
          <h2 className="t-body-sm font-maison-neue-demi tracking-[2px] leading-8 mb-4 text-xs font-medium uppercase">
            Index
          </h2>
          <AeditLink className="t-body-sm mb-2" pathname="/procedure-directory">
            Procedures A-Z
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/procedure-costs-directory">
            Procedure Costs A-Z
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/procedure-recovery-directory">
            Procedure Recovery A-Z
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/practices-directory">
            Practices A-Z
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/providers-directory">
            Providers A-Z
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/concern-directory">
            Concerns A-Z
          </AeditLink>

          {/* TODO update this once sitemap is up to date */}
          <AeditLink className="t-body-sm mb-2" pathname="/aedition/category">
            Categories A-Z
          </AeditLink>

          <AeditLink className="t-body-sm mb-2" pathname="/providers-by-specialty">
            By Specialty
          </AeditLink>
          <AeditLink className="t-body-sm mb-2" pathname="/locations">
            By Location
          </AeditLink>
        </div>

        <div className="flex flex-col">
          <h2 className="t-body-sm font-maison-neue-demi tracking-[2px] leading-8 mb-4 text-xs font-medium uppercase">
            Follow Us
          </h2>
          <a
            className="t-body-sm mb-2"
            href={INSTAGRAM_URL}
            target="_blank"
            rel="noopener"
            onClick={() => onSocialClicked('ig')}>
            Instagram
          </a>
          <a
            className="t-body-sm mb-2"
            href={FACEBOOK_URL}
            target="_blank"
            rel="noopener"
            onClick={() => onSocialClicked('facebook')}>
            Facebook
          </a>
          <a
            className="t-body-sm mb-2"
            href={YOUTUBE_URL}
            target="_blank"
            rel="noopener"
            onClick={() => onSocialClicked('youtube')}>
            YouTube
          </a>
          <a
            className="t-body-sm mb-2"
            href={PINTEREST_URL}
            target="_blank"
            rel="noopener"
            onClick={() => onSocialClicked('pinterest')}>
            Pinterest
          </a>
          <a
            className="t-body-sm mb-2"
            href={TIKTOK_URL}
            target="_blank"
            rel="noopener"
            onClick={() => onSocialClicked('tiktok')}>
            TikTok
          </a>
          <a
            className="t-body-sm mb-2"
            href={LINKEDIN_URL}
            target="_blank"
            rel="noopener"
            onClick={() => onSocialClicked('linkedin')}>
            LinkedIn
          </a>
        </div>

        <div className="w-full mt-4 col-span-2 sm:col-span-4 lg:mt-0 lg:col-span-2">
          <p className="t-overline mb-2">Subscribe to our Newsletter</p>

          <EmailCollect />

          <p className="t-overline text-dark-gray mt-2">
            &copy; {new Date().getFullYear()} AEDIT, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer

const _passwordValidation = (value, error) => {
  if (value.length < 6) {
    error = 'Password must be more than 6 characters'
  }

  return error
}

const _telephoneValidation = (value, error) => {
  const regex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)

  if (!regex) {
    error = 'Please enter a valid US phone number'
  }

  return error
}

const _emailValidation = (value, error) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

  if (!regex) {
    error = 'Invalid email address'
  }

  return error
}

const _fileUploadValidation = (value, error) => {
  if (Array.isArray(value) && value.length < 1) {
    error = 'Please select a file'
  }

  return error
}

const _selectValidation = (value, error) => {
  if (Array.isArray(value) && value.length < 1) {
    error = 'This field is required'
  }

  return error
}

export const checkValidation = (value, type) => {
  let error

  if (!value) return (error = 'This field is required.')

  switch (type) {
    case 'email':
      return _emailValidation(value, error)
    case 'telephone':
      return _telephoneValidation(value, error)
    case 'password':
      return _passwordValidation(value, error)
    case 'file':
      return _fileUploadValidation(value, error)
    case 'select':
      return _selectValidation(value, error)
  }

  return error
}
